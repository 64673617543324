import './css/Welcome.css';
import './css/Home.css';
function Welcome() {
    return (
        <div className="WelcomeText">
                <a className="BurningText hidden">TIME TO TAKE THINGS TO THE NEXT LEVEL</a>
        </div>
    );
}

export default Welcome;